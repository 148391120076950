import React, { Component } from 'react';
import IconButton from '@material-ui/core/IconButton';
import ArrowLeft from 'mdi-material-ui/ArrowLeft';
import { Link, graphql } from 'gatsby';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

import Content from '../components/Content';
import Layout from '../components/Layout';
import CallToAction from './sections/call-to-action';

const Section = styled('section')`
  .gatsby-resp-image-wrapper {
    max-width: none !important;
  }
  h3 {
    margin-bottom: 0;
  }
  article {
    position: relative;
  }
`;

const BackButton = styled(IconButton)`
  &&& {
    position: absolute;
    right: 1em;
    top: 0;
    & + h3,
    & + h3 + p {
      margin-right: 56px;
    }
    ${breakpoint('lg')`
      position: absolute;
      left: -48px;
      right: auto;
      top: 0;
      & + h3,
      & + h3 + p {
        margin-right: 0;
      }
  `};
  }
`;

class BlogPost extends Component {
  render() {
    const {
      data: { page, section },
    } = this.props;
    return (
      <Layout page={page}>
        <Section className="space--xxs">
          <div className="container">
            <div className="row justify-content-center">
              <article className="col-md-8">
                <BackButton component={Link} to="/projects">
                  <ArrowLeft />
                </BackButton>
                <h3>{page.frontmatter.title}</h3>
                <p>{page.frontmatter.subtitle}</p>
                <Content content={page.html} />
              </article>
            </div>
          </div>
        </Section>
        <CallToAction data={{ section }} />
      </Layout>
    );
  }
}

export class BlogPostTemplate extends Component {
  render() {
    const { title, subtitle, content } = this.props;
    return (
      <Section className="space--xxs">
        <div className="container">
          <div className="row justify-content-center">
            <article className="col-md-8">
              <h3>{title}</h3>
              <p>{subtitle}</p>
              <Content content={content} />
            </article>
          </div>
        </div>
      </Section>
    );
  }
}

export default BlogPost;

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        metaTitle
        metaDescription
        subtitle
        tags
      }
    }
    section: markdownRemark(frontmatter: { name: { eq: "call-to-action" } }) {
      frontmatter {
        title
        lead
        callToActionText
        callToActionLink
      }
    }
  }
`;
