import React, { Component } from 'react';
import Link from 'gatsby-link';

class CallToAction extends Component {
  render() {
    const {
      data: { section },
    } = this.props;
    return (
      <section className="space--xxs bg--secondary">
        <div className="container">
          <div className="cta cta--horizontal text-center-xs row">
            <div className="col-md-9">
              <h4>{section.frontmatter.title}</h4>
              <p className="lead">{section.frontmatter.lead}</p>
            </div>
            <div className="col-md-3 text-right text-center-xs">
              <Link
                className="btn btn--lg btn--primary-1 type--uppercase"
                to={section.frontmatter.callToActionLink}
              >
                <span className="btn__text">
                  {section.frontmatter.callToActionText}
                </span>
              </Link>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default CallToAction;
